.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 1.6rem;
    height: calc(8px + 1.6rem);
    margin-bottom: 24px;
    transform: translateX(-8px);
}

.input-wrapper {
    width: calc(100% + 16px);
    margin: 0 -8px;
    position: absolute;
    height: 16px;
}

.control-wrapper {
    width: 100%;
    position: absolute;
    height: 16px;
}

.input::-ms-track {
    appearance: none;
    background: transparent;
    border: transparent;
}

.input::-moz-range-track {
    appearance: none;
    background: transparent;
    border: transparent;
}

.input:focus::-webkit-slider-runnable-track {
    appearance: none;
    background: transparent;
    border: transparent;
}

.input::-ms-thumb {
    appearance: none;
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    cursor: grab;
    background-color: red;
}

.input::-ms-thumb:active {
    cursor: grabbing;
}

.input::-moz-range-thumb {
    appearance: none;
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0;
    border: 0 none;
    cursor: grab;
    background-color: red;
}

.input::-moz-range-thumb:active {
    cursor: grabbing;
}

.input::-webkit-slider-thumb {
    appearance: none;
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    cursor: grab;
    background-color: red;
}

.input::-webkit-slider-thumb:active {
    cursor: grabbing;
}


.input {
    position: absolute;
    width: 100%;
    pointer-events: none;
    appearance: none;
    height: 100%;
    opacity: 0;
    z-index: 3;
    padding: 0;
}

.rail {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    border-radius: 3px;
}

.inner-rail {
    position: absolute;
    height: 100%;
}

.control {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    @apply bg-bgHover;
    box-shadow: 0 2px 5px #0000004d;
    top: 50%;
    margin-left: -8px;
    transform: translate3d(0, -50%, 0);
    z-index: 2;
}