.carouselWrapper {
  width: 100%;
  position: relative;
}

.carouselContainer {
  position: relative;
}

.carouselArrowsOnTop {
  position: absolute;
  top: -46px;
  right: 0;

  display: flex;
  align-items: center;
  gap: 8px;
}

.carouselContainer .customSlide {
  display: flex !important;
  width: 100%;
}

.carouselContainer .slick-list {
  width: 100% !important;
  margin-bottom: 28px;
}

:global(.slick-track) {
  display: flex !important;
  gap: 16px;
}

.slick-slide {
  width: var(--card-width) !important;
}

.carouselContainer .slick-slide {
  padding: 0 10px;
}

.carouselContainer .slick-dots {
  display: flex !important;
  justify-content: center;
  gap: 8px;
}

.carouselContainer .slick-dots .slick-active {
  background-color: #28a745 !important;
}

.carouselContainer .slick-dots li {
  width: 20px;
  height: 4px;
  border-radius: 3px;
  background-color: #a0a0a0;
  margin: 0;
}

.carouselContainer .slick-dots li button {
  height: 8px;
  padding: 0;
}

.carouselContainer .slick-dots li button:before {
  height: 8px;
}

.carouselContainer &SideArrows {
  display: flex;
  gap: 4px;
}

.arrowWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 36px;
  height: 36px;

  background-color: #202020;
  border-radius: 50%;

  &.disabled {
    opacity: 0.5;

    cursor: not-allowed;
  }
}