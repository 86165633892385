@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .square {
        aspect-ratio: 1 / 1;
    }

    .skip-ink {
        text-decoration-skip-ink: none
    }

    .shadow-3xl {
        box-shadow: 0 8px 24px 0 #08090a14
    }

    .transition-all {
        transition: all 0.3s ease-in-out;
    }

    .transition-all-slow {
        transition: all 0.5s ease;
    }

    .font-default-regular {
        font-family: 'EuclidCircularA Regular', sans-serif;
        font-weight: 400;
    }

    .font-default-medium {
        font-family: 'EuclidCircularA Medium', sans-serif;
        font-weight: 500;
    }

    .font-default-semibold {
        font-family: 'EuclidCircularA SemiBold', sans-serif;
        font-weight: 600;
    }

    .font-default-bold {
        font-family: 'EuclidCircularA Bold', sans-serif;
        font-weight: 700;
    }

    .colors-gray-dark {
        color: rgba(123, 123, 123, 1);
    }

    .border-colors-dark {
        border-bottom: 1px solid rgba(123, 123, 123, 1);
    }

    .border-colors-dark:last-child {
        border-color: transparent;
    }
}


@font-face {
    font-family: 'EuclidCircularA Regular';
    src: url('../../shared/assets/fonts/EuclidCircularA-Regular.otf') format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'EuclidCircularA Medium';
    src: url('../../shared/assets/fonts/EuclidCircularA-Medium.otf') format('truetype');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'EuclidCircularA SemiBold';
    src: url('../../shared/assets/fonts/EuclidCircularA-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'EuclidCircularA Bold';
    src: url('../../shared/assets/fonts/EuclidCircularA-Bold.otf') format('truetype');
    font-weight: 700;
    font-display: swap;
}

body {
    @apply bg-body;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
}

input {
    background: transparent;
}

input:focus {
    outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@layer utilities {
    .grid-cols-auto-160 {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
  
    .grid-cols-auto-200 {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
  
    .grid-cols-auto-230 {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
  }